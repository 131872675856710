<template>
    <div class="d-flex flex-wrap flex-stack my-5">
        <h2 class="fs-2 fw-bold my-2">
            {{ $t('pages.system.setting.types.' + settingKey) }}
        </h2>

        <router-link to="/system/setting" class="btn btn-primary align-self-center">
            <span class="svg-icon svg-icon-2">
                <inline-svg src="/media/icons/duotune/arrows/arr021.svg"/>
            </span>
            {{ $t("pages.system.setting.title") }}
        </router-link>
    </div>
    <el-tabs v-model="tabModel" class="setting-tabs" @tab-click="changedTab">
        <el-tab-pane v-for="(setting, settingKey, settingIndex) in settings" :key="settingIndex" :label="$t('pages.system.setting.types.' + settingKey)" :name="settingKey">
            <div class="card" v-if="tabSkeleton">
                <div class="card-body d-flex flex-column p-9">
                    <el-skeleton />
                </div>
            </div>
            <el-form :model="form.data" ref="settingForm" v-else-if="tabModel == settingKey">
                <div class="card">
                    <div class="card-body d-flex flex-column p-9">
                        <div class="row fv-row mb-7 align-items-center" v-for="(item, itemKey, itemIndex) in setting.items" :key="itemIndex">
                            <div class="col-md-3 mb-2 mb-md-0">
                                <label class="fs-6 fw-bold" :class="{'required': item.options.required}">{{ item.title }}</label>
                            </div>
                            <div class="col-md-9">
                                <el-form-item :prop="itemKey" :rules="item.options.required && $validation.getMessage(['required'])" class="mb-0" v-init="form.data[itemKey] = item.value">
                                    <FormElement :siteSettingItem="{item: item, itemKey: itemKey}" v-model="form.data[itemKey]"></FormElement>
                                </el-form-item>
                            </div>
                        </div>
                        <div class="fv-row mt-5">
                            <el-form-item class="mb-0 text-end">
                                <button @click.prevent="onSubmit" :data-kt-indicator="form.loading ? 'on' : null" class="btn btn-lg btn-primary" type="button">
                                    <span v-if="!form.loading" class="indicator-label">{{ $t("btn.save") }}</span>
                                    <span v-if="form.loading" class="indicator-progress">
                                        {{ $t("messages.wait") }}
                                        <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                                </button>
                            </el-form-item>
                        </div>
                    </div>
                </div>
            </el-form>
        </el-tab-pane>
    </el-tabs>
</template>

<script>
import FormElement from "@/components/system/setting/form-element";
export default {
    name: "index",
    components: {
        FormElement
    },
    data(){
        return {
            tabSkeleton: true,
            tabModel: undefined,
            form: {
                loading: false,
                data: {}
            },
            settings: {}
        }
    },
    computed: {
        settingKey() {
            return this.$route.params.id;
        }
    },
    created(){
        if (!this.settingKey || !(this.settingKey.length > 0)) {
            this.$router.push({ path: "/system/setting" });
        }

        this.tabModel = this.settingKey;
    },
    mounted() {
        if (this.settingKey && this.settingKey.length > 0) {
            this.loadSettings();
        }
    },
    methods: {
        loadSettings(){
            this.tabSkeleton = true;

            this.axios.get(this.endpoints['system_setting']).then((response) => {
                let data = response.data.data;

                if(!data[this.settingKey]){
                    this.$router.push({ path: "/system/setting" });
                }

                this.settings = data;
            }).finally(() => {
                this.tabSkeleton = false;
            });
        },
        onSubmit(){
            this.$refs.settingForm.validate((valid) => {
                if(valid) {
                    this.form.loading = true;

                    this.axios.post(this.endpoints['system_setting'], this.form.data).then(response => {
                        this.onResponse(response.data);
                    }).catch(error => {
                        this.onResponseFailure(error.response.data)
                    }).finally(() => {
                        this.form.loading = false;
                    });

                } else {
                    return false;
                }
            });
        },
        changedTab(){
            this.$router.push({ path: "/system/setting/" + this.tabModel });
            this.tabSkeleton = true;
            this.form.data = {};
            setTimeout(() => {
                this.tabSkeleton = false;
            }, 0)
        }
    }
}
</script>

<style scoped>

</style>