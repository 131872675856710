<template>
    <el-select v-if="siteSettingItem.item.element == 'select'" v-model="value" class="w-100" :placeholder="$t('common.chooseSelect')" v-bind="siteSettingItem.item.options">
        <el-option v-for="(option, optionIndex) in siteSettingItem.item.items" :key="optionIndex" :value="option.val" :label="option.text"></el-option>
    </el-select>
    <el-input v-else-if="siteSettingItem.item.element == 'textarea'" v-model="value" type="textarea" v-bind="siteSettingItem.item.options"/>
    <label v-else-if="siteSettingItem.item.element == 'input' && siteSettingItem.item.options.type == 'radio'" class="form-check form-switch form-check-custom form-check-solid">
        <input class="form-check-input" type="checkbox" :value="true" v-model="value"/>
    </label>
    <el-upload
        v-else-if="siteSettingItem.item.element == 'input' && siteSettingItem.item.options.type == 'file'"
        class="upload-demo site-setting-upload"
        action="#"
        :on-change="(file, fileList) => { return handleChangeFile(siteSettingItem.itemKey, file, fileList); }"
        :on-remove="(file, fileList) => { return handleChangeFile(siteSettingItem.itemKey, file, fileList); }"
        :on-preview="(file) => { return handleFilePreview(file); }"
        :limit="1"
        :file-list="value ? [{name: siteSettingItem.itemKey, url: value}] : []"
        :auto-upload="false">
        <el-button type="primary">{{ $t('common.clickToUpload') }}</el-button>
    </el-upload>
    <el-input v-else-if="siteSettingItem.item.element == 'input'" v-model="value" v-bind="siteSettingItem.item.options"/>
    <CustomCkeditor v-else-if="siteSettingItem.item.element == 'editor'" :model="value" @updateModel="value = $event" v-bind="siteSettingItem.item.options"></CustomCkeditor>
    <div v-if="siteSettingItem.item.description" class="fs-7 fw-bold text-gray-400 lh-sm mt-2">{{ siteSettingItem.item.description }}</div>
</template>

<script>
import CustomCkeditor from "@/components/custom-ckeditor"
export default {
    name: "index",
    emit: ["update:modelValue", "updateUploadFiles"],
    props: {
        siteSettingItem: {type: Object, required: true},
        modelValue: {required: true}
    },
    components: {
        CustomCkeditor
    },
    data(){
        return {
            uploadFiles: {}
        }
    },
    computed:{
        value: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', value)
            }
        },
    },
    methods: {
        handleChangeFile(itemKey, file, fileList){
            if(fileList.length) {
                this.uploadFiles[itemKey] = fileList;
            } else {
                delete this.uploadFiles[itemKey];
            }

            this.$emit('updateUploadFiles', this.uploadFiles);
        },
        handleFilePreview(file){
            if(file.url){
                window.open(this.$root.appUrl + '/' + file.url, '_blank');
            }

            if(!file.url && file.raw){
                this.getBase64(file.raw).then(response => {
                    let w = window.open('about:blank');

                    setTimeout(function(){ //FireFox seems to require a setTimeout for this to work.
                        w.document.body.appendChild(w.document.createElement('iframe'))
                            .src = response;
                        w.document.getElementsByTagName("iframe")[0].style.width = '100%';
                        w.document.getElementsByTagName("iframe")[0].style.height = '100%';
                        w.document.getElementsByTagName("iframe")[0].style.border = 'none'
                    }, 0);
                });
            }
        },
    }
}
</script>

<style scoped>

</style>